<template>
  <el-select
    v-model="server"
    placeholder="Select Server"
    size="large">
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.serverName"
      :value="item.id" />
  </el-select> 
</template>
  
<script>
export default {
  name: "ServerSwitch"
}
</script>

 <script setup>
  import { currentServer, loadServers, switchServer } from '@/api/server'
  import {onMounted, ref, watch } from 'vue'

  const options = ref([]);
  const server = ref("");

  onMounted(() => {
      loadServers().then((res) =>  options.value = res.data);
      currentServer().then(res => server.value = res.data);
  });

  watch(server, (newServer) => switchServer(newServer))

 </script>
 
 <style lang="scss" scoped>
    .el-select{
      padding-top: 5px !important;
    }
 </style>