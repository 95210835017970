import Vue from "vue";
import { createWebHistory, createRouter } from "vue-router";
/* Layout */
import Layout from "@/layout";

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        name: "redirect",
        component: () => import("@/views/redirect/index")
      }
    ]
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true
  },
  {
    path: "/auth-redirect",
    component: () => import("@/views/login/auth-redirect"),
    hidden: true
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true
  },
  {
    path: "/",
    component: Layout,
    hidden: true,
    redirect: "dashboard",
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/dashboard/index"),
        name: "Dashboard",
        meta: { title: "Dashboard", icon: "user", affix: true }
      }
    ]
  },
  {
    path: "/profile",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        component: () => import("@/views/profile/index"),
        name: "Profile",
        meta: { title: "Profile", icon: "user", noCache: true }
      }
    ]
  }
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  {
    path: "/address",
    component: Layout,
    alwaysShow: true, // will always show the root menu
    name: "",
    permission: ["address"],
    meta: {
      title: "Address",
      icon: "dashboard",
      roles: ["address"], // you can set roles in root nav
      breadcrumb: false
    },
    children: [
      {
        path: "",
        component: () => import("@/views/address/addressIndex"),
        name: "addressIndex",
        hidden: false,
        meta: {
          title: "All Addresses",
          roles: ["api.address.view"]
        }
      },
      {
        path: "add",
        component: () => import("@/views/address/addressView"),
        name: "addressAdd",
        hidden: false,
        meta: {
          title: "Add Address",
          roles: ["api.address.add"],
          newAddress: true
        }
      },
      {
        path: "view/:id",
        component: () => import("@/views/address/addressView"),
        name: "viewAddress",
        hidden: true,
        meta: {
          title: "View Address",
          roles: ["api.address.view"],
          newAddress: false
        }
      }
    ]
  },
  {
    path: "/poi",
    component: Layout,
    alwaysShow: true, // will always show the root menu
    name: "",
    permission: ["poi"],
    meta: {
      title: "Poi",
      icon: "dashboard",
      roles: ["poi"], // you can set roles in root nav
      breadcrumb: false
    },
    children: [
      {
        path: "",
        component: () => import("@/views/poi/poiIndex"),
        name: "poiIndex",
        hidden: false,
        meta: {
          title: "All Pois",
          roles: ["api.poi.view"]
        }
      },
      {
        path: "add",
        component: () => import("@/views/poi/poiView"),
        name: "poiAdd",
        hidden: false,
        meta: {
          title: "Add poi",
          roles: ["api.poi.add"],
          newPoi: true
        }
      },
      {
        path: "view/:id",
        component: () => import("@/views/poi/poiView"),
        name: "viewPoi",
        hidden: true,
        meta: {
          title: "View poi",
          roles: ["api.poi.view"],
          newPoi: false
        }
      }
    ]
  },
  {
    path: "/emails",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/email/emailView"),
        name: "emailView",
        meta: {
          title: "Emails",
          icon: "dashboard",
          roles: ["email", "api.emails.view"]
        }
      }
    ]
  },
  {
    path: "/backend",
    component: Layout,
    alwaysShow: true, // will always show the root menu
    name: "System",
    permission: ["ADMIN"],
    meta: {
      title: "System",
      icon: "dashboard",
      roles: ["ADMIN"], // you can set roles in root nav
      breadcrumb: false
    },
    children: [
      {
        path: "user",
        component: () => import("@/views/system/user/userIndex"),
        name: "User",
        meta: {
          title: "User",
          roles: ["api.user.all"] // or you can only set roles in sub nav
        }
      },
      {
        path: "user/add",
        component: () => import("@/views/system/user/userView"),
        name: "userAdd",
        hidden: true,
        meta: {
          title: "Benutzer hinzufügen",
          roles: ["api.user.add"],
          newUser: true
        }
      },
      {
        path: "user/view/:id",
        component: () => import("@/views/system/user/userView"),
        name: "viewUser",
        hidden: true,
        meta: {
          title: "User",
          roles: ["ADMIN"],
          newUser: false
        }
      },
      {
        path: "role",
        component: () => import("@/views/system/group/groupIndex"),
        name: "Gruppen",
        meta: {
          title: "Gruppen",
          roles: ["api.group.all"]
        }
      },
      {
        path: "permission",
        component: () => import("@/views/system/permissions/permissionIndex"),
        name: "Rechte",
        meta: {
          title: "Rechte",
          roles: ["api.permission.all"]
        }
      },
      {
        path: "role/add",
        component: () => import("@/views/system/group/groupView"),
        name: "groupAdd",
        hidden: true,
        meta: {
          title: "Gruppe hinzufügen",
          roles: ["api.group.add"],
          newRole: true
        }
      },
      {
        path: "role/view/:id",
        component: () => import("@/views/system/group/groupView"),
        name: "groupView",
        hidden: true,
        meta: {
          title: "Gruppe ",
          roles: ["api.group.all"],
          edit: true
        }
      },
      {
        path: "server",
        component: () => import("@/views/system/server/serverIndex"),
        name: "Server",
        meta: {
          title: "Server",
          roles: ["api.server.all"]
        }
      },
      {
        path: "server/add",
        component: () => import("@/views/system/server/serverView"),
        name: "serverAdd",
        hidden: true,
        meta: {
          title: "Server hinzufügen",
          roles: ["api.server.add"],
          newServer: true
        }
      },
      {
        path: "server/view/:id",
        component: () => import("@/views/system/server/serverView"),
        name: "serverView",
        hidden: true,
        meta: {
          title: "Server ",
          roles: ["api.server.all"],
          newServer: false,
          edit: true
        }
      }
    ]
  },

  /*
  /!** when your routing map is too long, you can split it into small modules **!/
  componentsRouter,
  chartsRouter,
  nestedRouter,
  tableRouter,
*/
  // 404 page must be placed at the end !!!
  { path: "/:catchAll(.*)", redirect: "/404", hidden: true }
];

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(),
    constantRoutes
  });
  router.matcher = newRouter.matcher; // reset router
}

export const getRouteByName = (name) => {
  let route = null;
  const routes = asyncRoutes.concat(constantRoutes);
  Object.keys(routes).some((path) => {
    if (routes[path].name === name) {
      route = routes[path];
      return true;
    }
  });
  return route;
};

export default router;
