import { param } from "@/utils";
import request from "@/utils/request";

export function loadServers() {
  return request({
    method: "get",
    url: "/server/list"
  });
}

export function getServer(serverID) {
  return request({
    method: "get",
    url: "/server/" + serverID
  });
}

export function currentServer() {
  return request({
    method: "get",
    url: "/server/current"
  });
}

export function switchServer(server) {
  return request({
    method: "post",
    url: "/server/use",
    params: { id: server }
  });
}

export function updateServer(server) {
  return request({
    method: "post",
    url: "/server/update/" + server.id,
    data: server
  });
}

export function createServer(server) {
  return request({
    method: "put",
    url: "/server/add",
    data: server
  });
}

export function deleteServer(serverID) {
  return request({
    method: "delete",
    url: "/server/delete/" + serverID
  });
}
