import deLocale from "element-plus/lib/locale/lang/de";
import enLocale from "element-plus/lib/locale/lang/en";

export const messages = {
  en: {
    ...enLocale,
    calendar: {
      popup: {
        customRepeat: {
          datepicker: {
            placeholder: "Pick a day"
          },
          emptyTable: "No Events picked"
        }
      }
    },
    date: {
      month: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    }
  },
  de: {
    ...deLocale,
    calendar: {
      popup: {
        customRepeat: {
          datepicker: {
            placeholder: "Tag wählen"
          },
          emptyTable: "Noch keine Termine"
        }
      }
    },
    date: {
      month: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ]
    }
  }
};
