import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data
  });
}

export function getInfo() {
  return request({
    url: "/user",
    method: "get"
  });
}

export function getUsers() {
  return request({
    url: "/user/all",
    method: "get"
  });
}

export function getUser(id) {
  return request({
    url: "/user/get/" + id,
    method: "get"
  });
}

export function findUserByName(name) {
  return request({
    url: `/user/find?name=${name}&filter=id,firstName,lastName`,
    method: "get"
  });
}

export function addUser(data) {
  return request({
    url: "/user/add",
    method: "put",
    data
  });
}

export function updateUser(id, data) {
  return request({
    url: `/user/update/${id}`,
    method: "post",
    data
  });
}

export function banUser(user) {
  const form = new FormData();
  form.append("id", user.id);
  form.append("enabled", user.enabled);

  return request({
    url: "/user/ban",
    method: "post",
    data: form
  });
}

export function deleteUser(id) {
  return request({
    url: `/user/delete/${id}`,
    method: "delete"
  });
}

export function updatePass(oldPassword, newPassword, confirmNewPassword) {
  return request({
    url: "/user/pass",
    method: "post",
    data: { oldPassword, newPassword, confirmNewPassword }
  });
}

export function logout() {
  return request({
    url: "/logout",
    method: "post"
  });
}
