import Vue from "vue";
import { createApp } from "vue";

import Cookies from "js-cookie";

import "normalize.css/normalize.css"; // a modern alternative to CSS resets
import Element from "element-plus";
import "./styles/element-variables.scss";

import "@/styles/index.scss"; // global css

import App from "./App";
import store from "./store";
import router from "./router";

import "./icons"; // icon
import "./permission"; // permission control
import { messages } from "./i18n";
import icons from "linearicons";
import { createI18n } from "vue-i18n";

import VueSweetalert2 from "vue-sweetalert2";

const i18n = createI18n({
  locale: "de",
  messages
});

const app = createApp(App);
app
  .use(router)
  .use(store)
  .use(icons)
  .use(VueSweetalert2)
  .use(i18n)
  .use(Element, {
    size: Cookies.get("size") || "medium" // set element-ui default size
  })
  .mount("#app");
